/* OrgChartComponent.css */
.org-chart-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 1px;
}

.chart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px; 
}

.employee-detail {
    border: 1px solid #ddd;
    padding: 15px;
    margin-top: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 90%;
    width: 400px; 
    margin-left: auto;
    margin-right: auto;
}


